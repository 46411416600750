.chat-view-aros {
  top: 0;
  margin: 0;
  height: 60vh;
  max-width: 900px;
  width: 70vw;
  background-color: rgba(0, 0, 0, 0);
  transform: translateX(-50%);
  left: 50%;
}

.chat-view-aros__messages {
  overflow-y: hidden;
}

.chat-view-aros__message {
  font-size: 20pt;
  max-width: 75%;
  background-color: rgba(0, 0, 0, 0.8);
}
.chat-view-aros__message--from-You {
  background-color: rgba(0, 0, 0, 0.8);
  color: rgb(255, 255, 255, 0.7);
}
.chat-view-aros--open {
  background-color: rgba(0, 0, 0, 0);
  transform: translateY(-100%);
}

.chat-view-aros--novisuals {
  transform: translateY(0%);
}
