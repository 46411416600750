.aros-art-selector-wrapper {
  position: absolute;
  bottom: 0px;
}

.aros-art-selector {
  width: 100vw;
  height: 32vh;

  overflow: auto;
  white-space: nowrap;
}

.aros-art-selector-wrapper > h2 {
  text-align: center;
  color: white;
  margin: 0;
}

.aros-art-images {
  display: flex;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  align-items: flex-start;

  width: 100vw;
  height: 30vh;
}

.row-wrapper {
  border-style: 100px;
  border-width: 2px;
  border-color: white;

  display: inline;
  margin-left: 50px;
  margin-right: 50px;
  height: 100%;
  width: 100%;
}

.aros-art-image {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.aros-art-image > img {
  max-height: 90%;
  height: 90%;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0.4em;
}

.aros-art-image > label {
  color: white;
  font-size: 24px;
  margin-top: 0.5em;
}
