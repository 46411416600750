.center-aros .right-aros {
  right: 0;
  position: fixed;
}

.global-aros {
  width: 100vw;
  height: 100vh;
}

.enter-button-aros {
  box-sizing: inherit;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #000000 none;
  color: white;
  font-family: Helvetica, Lato, "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  height: 100%;
  width: 100%;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  user-select: none;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-tap-highlight-color: transparent;
  font-size: 8rem;
}

@media (max-width: 768px) {
  .global-aros h1 {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 100%;
    font-size: 16vw;
  }
}
