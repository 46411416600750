body {
  height: 100%;
  width: 100%;
}

.clearfix::after {
  clear: both;
  content: ".";
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

.chat-view-test {
  width: 100vw !important;
  position: absolute !important;
  height: 100% !important;
  bottom: 0 !important;
}

.chat-view-test > div {
  position: relative !important;
  height: 100% !important;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
}

.mute-button-container {
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
  z-index: 100;
  width: 100%;
  margin: -20px 0 0 auto;
  width: 64px;
  cursor: pointer;
}

.mute-button {
  width: 1.5em !important;
  padding-right: 8px;
}
