.global {
  color: #666666;
  background-color: #9d9d9d;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 1;
}

.global h1 {
  font-size: 150px;
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20vw;
  margin: 0;
  position: fixed;
}

.center {
  font-size: 20px;
  width: 60vw;
  height: 75vh;
  left: 50%;
  /* top: 25vh; */
  transform: translateX(-50%);
  margin-bottom: 0;
  position: fixed;
}

.center p {
  margin: 0;
  overflow-y: scroll;
  /* -ms-overflow-style: */
  /* scrollbar-width: none;  */
  height: 100%;
}

a {
  color: #505050;
}

.enter-button {
  position: absolute;
  box-sizing: inherit;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #5b92e5 none;
  color: white;
  font-family: Helvetica, Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 50px 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  user-select: none;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-tap-highlight-color: transparent;
  font-size: 1rem;
  right: 20vw;
  bottom: 50px;
  transform: translateX(-50%);
}

@media (max-width: 1000px) {
  .enter-button {
    right: 5vw;
    transform: translateX(0);
  }

  .global h1 {
    left: 5vw;
  }

  .center {
    width: 90vw;
  }
}

@media (max-width: 768px) {
  .global h1 {
    font-size: 16vw;
  }

  .center {
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: calc(100vh - 200px);
  }
}
