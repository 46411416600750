.countdown{
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    width: 200px;
    padding: 20px;
    background-color: #333333;
    color: white;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    border: 2px solid #ffffff;
    box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.75);
}

.countdown-bounding-box{
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);;
}