.popup-content {
  border-radius: 5px;
  padding: 0.5rem;
  background-color: #333333;
  color: #ffffff;
  border-right: 0px;
  border-left: 0px;
  border-top: 2px solid #9a9595;
  border-bottom: 2px solid #9a9595;
}

html[data-theme="dark"] .popup-arrow > svg {
  color: #333333;
  stroke-width: 2px;
  stroke: #9a9595;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
}

[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.modal {
  font-size: 11pt;
}
.modal > .header {
  width: 100%;
  /*border-bottom: 1px solid gray;*/
  font-weight: bold;
  font-size: 20pt;
  text-align: center;
  padding-top: 20px;
}
.modal > .header-dark {
  width: 100%;
  /*border-bottom: 1px solid gray;*/
  font-weight: bold;
  font-size: 20pt;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  background-color: #1a1a1a;
}

.modal > .content b,
.modal > .header2 {
  width: 100%;
  font-weight: bold;
  font-size: 24pt;
  text-align: center;
  padding-top: 2px;
  font-weight: normal;
  line-height: 100%;
}

.modal > .content i,
.modal > .header3 {
  width: 100%;
  font-weight: bold;
  font-size: 16pt;
  text-align: center;
  padding-bottom: 2px;
  font-style: normal;
}
.modal > .content {
  margin: auto;
  max-width: 280px;
  text-align: center;
  width: 100%;
  padding: 10px 5px;
}

.column > svg {
  margin-left: 80px;
  margin-top: 60px;
}

.full {
  width: 100%;
  margin: "0";
}

.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 26pt;
  background: #ffffff;
  border-radius: 18px;
}

.buy-page-listing {
  font-size: 8pt;
  padding-left: 12px;
  margin-top: 3px;
  margin-bottom: 3px;
  line-height: 110%;
}

.buy-page-listing > li span {
  position: relative;
  left: -5px;
}
