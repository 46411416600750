*:focus {
  outline: none;
}

.chat-view {
  display: flex;
  position: absolute;
  overflow: hidden;
  flex-direction: column;
  margin: auto;
  width: 620px;
  height: 100%;
  bottom: 0;
  border-radius: 1.5em 1.5em 0 0;
  background-color: rgba(64, 64, 64, 0);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  left: 50%;
  transform: translate(-50%, calc(100% - 2.3em));
  transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

.chat-view__messages {
  flex: 1;
  padding: 0 1em 1em;
  overflow-y: scroll;
}
.chat-view__pull-container {
  padding: 1em;
}

.chat-view__openbutton {
  text-align: center;
  margin: -7px auto 0 auto;
  display: block;
  font-weight: bold;
  width: 16px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

select.chat-view__personaselect {
  background-color: transparent;
  color: white;
  border: 1px solid;
  border-radius: 0.2em;
  font-size: 1em;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.51em;
}

.chat-view__personaselect > option {
  background: rgb(200, 200, 200);
  color: black;
}

.chat-view__message-container {
  margin-bottom: 16px;
}
.chat-view__message {
  padding: 6px 12px;
  float: left;
  max-width: 85%;
  border-radius: 1em;
  word-wrap: break-word;
  line-height: 1.4;
  background-color: #000;
  color: white;
}
.chat-view__message--from-You {
  float: right;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.chat-view__bottom {
  display: flex;
}
.chat-view__bottom__reply,
.chat-view__bottom__reply::placeholder {
  flex: 1;
  padding: 1em;
  border: none;
  margin: 1em;
  color: white;
  font-size: 1em;
}
.chat-view__bottom__reply {
  background-color: rgba(10%, 10%, 10%, 10%);
  border-radius: 1em;
}

.chat-view__bottom__timer {
  padding: 1em;
  color: rgba(255, 255, 255, 0.2);
}
.chat-view--fully-closed {
  transform: translateY(0);
}
.chat-view--open {
  transform: translate(-50%, 0);
  background-color: rgba(64, 64, 64, 0.5);
}

@media (max-width: 768px) {
  .chat-view {
    width: auto;
  }
}
